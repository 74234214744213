import {
  enLanguageSettings,
  elLanguageSettings,
} from "./LanguageSettings/translations";
import { enThemeSettings, elThemeSettings } from "./ThemeSettings/translations";

export const enDisplayTab = {
  SETTINGS_DISPLAY_TAB_LABEL: "Display",
  SETTINGS_DISPLAY_HEADER: "Display",
  ...enLanguageSettings,
  ...enThemeSettings,
};

export const elDisplayTab = {
  SETTINGS_DISPLAY_TAB_LABEL: "Εμφάνιση",
  SETTINGS_DISPLAY_HEADER: "Εμφάνιση",
  ...elLanguageSettings,
  ...elThemeSettings,
};
