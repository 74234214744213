export enum EChartDrawTypes {
  Line = "line",
  Area = "area",
  Candle = "candle",
  Histogram = "histogram",
}

export enum SignalValues {
  Neutral = 0,
  Buy = 1,
  Sell = 2,
  Bullish = 1,
  Bearish = 2,
  Golden = 1,
  Death = 2,
  Positive = 1,
  Negative = 2,
}

export enum SignalElapsed {
  One = 1,
}

export enum ProviderTypes {
  Cex = "cex",
  Dex = "dex",
  Other = "other",
}

export enum AppModes {
  Development = "dev",
  Production = "prod",
}
