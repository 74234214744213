import { createSlice } from "@reduxjs/toolkit";
import { first } from "lodash";
import { api } from "../../features/api";
import { calculateSignals, fetchSignalsByPair } from "../actions/signal";
import { AXIOS_STATUSES } from "./../../types/entities";
import { fetchCandles } from "../actions/charts";

export interface TChartsState {
  stored: {
    symbol: string;
    name?: string;
    data: Array<any>;
    filters: {
      period: any | null;
      pair: any | null;
    };
    view: {
      indicators: Array<any>;
      patterns: Array<any>;
      signals: Array<any>;
      autoSize: boolean;
      fullScreen: boolean;
    };
  };
  live: {
    json: string;
    data: Array<any>;
    filters: {
      period: any | null;
      provider: any | null;
      pair: any | null;
    };
    view: {
      modeActive: boolean;
      indicators: Array<any>;
      patterns: Array<any>;
      signals: Array<any>;
    };
  };
  status: string | null;
}

const initialState: TChartsState = {
  stored: {
    symbol: "",
    name: "",
    data: [],
    filters: {
      period: null,
      pair: null,
    },
    view: {
      indicators: [],
      patterns: [],
      signals: [],
      autoSize: true,
      fullScreen: false,
    },
  },
  live: {
    json: "",
    data: [],
    filters: {
      period: null,
      provider: null,
      pair: null,
    },
    view: {
      modeActive: false,
      indicators: [],
      patterns: [],
      signals: [],
    },
  },
  status: null,
};

const chartsSlice = createSlice({
  name: "charts",
  initialState,
  reducers: {
    storedPeriodChanged: (state, action) => {
      state.stored.filters.period = action.payload;
    },
    storedPairChanged: (state, action) => {
      state.stored.filters.pair = action.payload;
    },
    storedIndicatorsChanged: (state, action) => {
      state.stored.view.indicators = action.payload;
    },
    storedPatternsChanged: (state, action) => {
      state.stored.view.patterns = action.payload;
    },
    storedSignalsChanged: (state, action) => {
      state.stored.view.signals = action.payload;
    },
    livePeriodChanged: (state, action) => {
      state.live.filters.period = action.payload;
    },
    liveProviderChanged: (state, action) => {
      state.live.filters.provider = action.payload;
    },
    livePairChanged: (state, action) => {
      state.live.filters.pair = action.payload;
    },
    openInFullScreen: (state) => {
      state.stored.view.fullScreen = true;
      state.stored.view.autoSize = false;
    },
    closeFullScreen: (state) => {
      state.stored.view.fullScreen = false;
      state.stored.view.autoSize = true;
    },
    liveJsonChanged: (state, action) => {
      state.live.json = action.payload;
    },
    liveDataChanged: (state, action) => {
      state.live.data = action.payload;
    },
    liveIndicatorsChanged: (state, action) => {
      state.live.view.indicators = action.payload;
    },
    livePatternsChanged: (state, action) => {
      state.live.view.patterns = action.payload;
    },
    liveSignalsChanged: (state, action) => {
      state.live.view.signals = action.payload;
    },
    toggleLiveMode: (state, action) => {
      state.live.view.modeActive = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(calculateSignals.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(calculateSignals.fulfilled, (state, action) => {
        state.live.data = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(calculateSignals.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchSignalsByPair.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchSignalsByPair.fulfilled, (state, action) => {
        const row = first(action.payload);
        state.stored.symbol = row?.SYMBOL.replaceAll(row.SYMBOL_PREFIX, "");
        state.stored.name = first(action.payload)?.NAME;
        state.stored.data = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchSignalsByPair.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchCandles.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchCandles.fulfilled, (state, action) => {
        state.live.json = JSON.stringify(action.payload, null, 2);
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchCandles.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addMatcher(
        api.endpoints.getActivePeriods.matchFulfilled,
        (state, { payload }) => {
          state.stored.filters.period = first(payload);
        }
      )
      .addMatcher(
        api.endpoints.getActivePairs.matchFulfilled,
        (state, { payload }) => {
          state.stored.filters.pair = first(payload);
        }
      )
      .addMatcher(
        api.endpoints.getSourcePeriods.matchFulfilled,
        (state, { payload }) => {
          state.live.filters.period = first(payload);
        }
      )
      .addMatcher(
        api.endpoints.getLiveProviders.matchFulfilled,
        (state, { payload }) => {
          state.live.filters.provider = first(payload);
        }
      )
      .addMatcher(
        api.endpoints.getLivePairs.matchFulfilled,
        (state, { payload }) => {
          state.live.filters.pair = first(payload);
        }
      );
  },
});

export const {
  storedPeriodChanged,
  storedPairChanged,
  storedSignalsChanged,
  storedIndicatorsChanged,
  storedPatternsChanged,
  livePeriodChanged,
  liveProviderChanged,
  livePairChanged,
  openInFullScreen,
  closeFullScreen,
  liveIndicatorsChanged,
  livePatternsChanged,
  liveSignalsChanged,
  liveJsonChanged,
  liveDataChanged,
  toggleLiveMode,
} = chartsSlice.actions;
export default chartsSlice.reducer;
