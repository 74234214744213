import { enDisplayTab, elDisplayTab } from "./Display/translations";
import {
  enNotificationsTab,
  elNotificationsTab,
} from "./Notifications/translations";
import { elSecurityTab, enSecurityTab } from "./Security/translations";

export const enSettingsPage = {
  ...enDisplayTab,
  ...enNotificationsTab,
  ...enSecurityTab,
};

export const elSettingsPage = {
  ...elDisplayTab,
  ...elNotificationsTab,
  ...elSecurityTab,
};
