import { createTheme } from "@mui/material";

export const light = createTheme({
  typography: {
    fontFamily: `"Calibri", "Helvetica", "Arial", sans-serif`,
  },
  palette: {
    mode: "light",
  },
});

export const dark = createTheme({
  typography: {
    fontFamily: `"Calibri", "Helvetica", "Arial", sans-serif`,
  },
  palette: {
    mode: "dark",
  },
});
