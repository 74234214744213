import React from "react";
import { Box } from "@mui/material";
import ReportSettings from "./ReportSettings/ReportSettings";

const NotificationsTab: React.FC = () => {
  return (
    <Box sx={{ width: "100%", padding: "0px 10px 0px 10px" }}>
      <ReportSettings />
    </Box>
  );
};

export default NotificationsTab;
