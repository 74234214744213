import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ROUTES } from "../../../constants/routes";
import { TAppState } from "../../../store/reducers";
import { commonSelector } from "../../../components/selectors";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { theme } = useSelector((state: TAppState) => commonSelector(state));

  const navigateToHome = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <Box>
      <Box
        sx={{
          position: "fixed",
          left: "50%",
          top: "40%",
          width: "40%",
          WebkitTransform: "translate(-50%, -50%)",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <Box style={{ fontSize: "4em" }}>{t("NOT_FOUND_HEADER")}</Box>
        <Box style={{ fontSize: "2em" }}>{t("NOT_FOUND_MESSAGE")}</Box>
        <Box>
          <Button
            color={theme === "light" ? "inherit" : "primary"}
            onClick={navigateToHome}
          >
            <ArrowForwardIcon sx={{ fontSize: "5em" }} />
          </Button>
        </Box>
      </Box>
      {/* <h1 style={{ fontSize: "4em" }}>{t("NOT_FOUND_HEADER_MESSAGE")}</h1>
      <span style={{ cursor: "pointer" }} onClick={() => navigateHome()}>
        {t("NOT_FOUND_HOME_PAGE_LINK")}
      </span> */}
    </Box>
  );
};

export default NotFoundPage;
