export const enReportSettings = {
  SETTINGS_NOTIFICATIONS_REPORT_HEADER: "Sync and signal reports",
  SETTINGS_NOTIFICATIONS_REPORT_EMAIL_HEADER: "Via Email",
  SETTINGS_NOTIFICATIONS_REPORT_EMAIL_MESSAGE:
    "Enable if you want to receive notifications for sync and signal events via email.",
  SETTINGS_NOTIFICATIONS_REPORT_EMAIL_STATUS_ON: "On",
  SETTINGS_NOTIFICATIONS_REPORT_EMAIL_STATUS_OFF: "Off",
  SETTINGS_NOTIFICATIONS_REPORT_EMAIL_ENABLE_BUTTON: "Enable",
  SETTINGS_NOTIFICATIONS_REPORT_EMAIL_DISABLE_BUTTON: "Disable",
};

export const elReportSettings = {
  SETTINGS_NOTIFICATIONS_REPORT_HEADER: "Αναφορές σημάτων και συγχρονισμού",
  SETTINGS_NOTIFICATIONS_REPORT_EMAIL_HEADER: "Μέσω ηλεκτρονικού ταχυδρομείου",
  SETTINGS_NOTIFICATIONS_REPORT_EMAIL_MESSAGE:
    "Ενεργοποιήστε εάν θέλετε να λαμβάνετε ειδοποιήσεις για συμβάντα συγχρονισμού και σημάτων μέσω email.",
  SETTINGS_NOTIFICATIONS_REPORT_EMAIL_STATUS_ON: "Ενεργό",
  SETTINGS_NOTIFICATIONS_REPORT_EMAIL_STATUS_OFF: "Ανενεργό",
  SETTINGS_NOTIFICATIONS_REPORT_EMAIL_ENABLE_BUTTON: "Ενεργοποίηση",
  SETTINGS_NOTIFICATIONS_REPORT_EMAIL_DISABLE_BUTTON: "Απενεργοποίηση",
};
