import * as React from "react";
import { MuiOtpInput } from "mui-one-time-password-input";

type Props = {
  onChange?: (value: any) => void;
  onKeyUp?: (value: any) => void;
  onComplete: (value: any) => void;
  length?: number;
  autoFocus?: boolean;
};

const BaseAuthCode: React.FC<Props> = ({
  onChange = (value: any) => {},
  onKeyUp = (value: any) => {},
  onComplete,
  length = 6,
  autoFocus = true,
}) => {
  const [otp, setOtp] = React.useState("");

  const handleChange = (value: any) => {
    onChange(value);
    setOtp(value);
  };

  return (
    <MuiOtpInput
      value={otp}
      length={length}
      onChange={handleChange}
      onComplete={onComplete}
      onKeyUp={onKeyUp}
      autoFocus={autoFocus}
    />
  );
};

export default BaseAuthCode;
