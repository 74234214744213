import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { OTHER_FETCH_RANKING, OTHER_FETCH_FEARANDGREED } from "./_types";
import {
  setInterceptors,
  clearInterceptors,
} from "../../features/interceptors/app";
import { fundamentalsUrl } from "../../constants/endpoints";

type TFetchRankingPayload = undefined;

type TFetchFearAndGreedPayload = {
  type: string;
};

export const fetchRanking = createAsyncThunk(
  OTHER_FETCH_RANKING,
  async (params: TFetchRankingPayload) => {
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.get(
      `${fundamentalsUrl}/ranking`
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const fetchFearAndGreed = createAsyncThunk(
  OTHER_FETCH_FEARANDGREED,
  async (params: TFetchFearAndGreedPayload) => {
    const { type } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.get(
      `${fundamentalsUrl}/fearandgreed?type=${type}`
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);
