import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ANALYSIS_FETCH_BY_OPENED_AT, ANALYSIS_FETCH_BY_PAIR } from "./_types";
import {
  setInterceptors,
  clearInterceptors,
} from "./../../features/interceptors/app";
import { analysisUrl } from "./../../constants/endpoints";

type TFetchAnalysisPayload = {
  analysisId: number | null;
  periodId: number | null;
  openedAt?: any | null;
  closedAt?: any | null;
  pairId?: string | null;
};

export const fetchAnalysisByOpenedAt = createAsyncThunk(
  ANALYSIS_FETCH_BY_OPENED_AT,
  async (params: TFetchAnalysisPayload) => {
    const { analysisId, periodId, openedAt } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.get(
      `${analysisUrl}?analysisId=${analysisId}&periodId=${periodId}&openedAt=${openedAt.toSeconds()}`
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const fetchAnalysisByPair = createAsyncThunk(
  ANALYSIS_FETCH_BY_PAIR,
  async (params: TFetchAnalysisPayload) => {
    const { analysisId, periodId, pairId } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.get(
      `${analysisUrl}?analysisId=${analysisId}&periodId=${periodId}&pairId=${pairId}`
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);
