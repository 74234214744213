import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CANDLES_FETCH } from "./_types";
import {
  setInterceptors,
  clearInterceptors,
} from "../../features/interceptors/app";
import { candlesUrl } from "./../../constants/endpoints";

type TFetchCandlesPayload = {
  period: any;
  provider: any;
  pair: any;
};

export const fetchCandles = createAsyncThunk(
  CANDLES_FETCH,
  async (params: TFetchCandlesPayload) => {
    const { period, provider, pair } = params;
    const periodId = period.ID;
    const providerId = provider.ID;
    const pairId = pair.ID;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    let url = `${candlesUrl}?periodId=${periodId}`;
    url += `&providerId=${providerId}`;
    url += `&pairId=${pairId}`;
    const response = await intercepted.instance.get(url);
    clearInterceptors(intercepted);
    return response.data;
  }
);
