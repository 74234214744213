export const enTfaSetupPage = {
  TFA_SETUP_PAGE_HEADER: "Enable two factor authentication",
  TFA_SETUP_PAGE_STEP_1_HEADER:
    "1. Scan this QR code with your verification app",
  TFA_SETUP_PAGE_STEP_1_CONTENT:
    "Once your app reads the QR code, you will get a 6-digit code.",
  TFA_SETUP_PAGE_STEP_1_TIP:
    "You can save the following text to recover your account if you lose access to your phone or verification app.",
  TFA_SETUP_PAGE_STEP_2_HEADER: "2. Enter the 6-digit code here",
  TFA_SETUP_PAGE_STEP_2_CONTENT:
    "Enter the code on the field below. Once connected, we will accept generated codes each time you log in.",
  TFA_SETUP_PAGE_BUTTON: "Submit",
};

export const elTfaSetupPage = {
  TFA_SETUP_PAGE_HEADER: "Ενεργοποίηση ταυτοποίησης δύο παραγόντων",
  TFA_SETUP_PAGE_STEP_1_HEADER:
    "1. Σκανάρετε τον κωδικό QR με την εφαρμογή επαλήθευσης σας",
  TFA_SETUP_PAGE_STEP_1_CONTENT:
    "Όταν η εφαρμογή σας διαβάσει τον κωδικό QR, θα λάβετε έναν κωδικό 6-ψηφίων.",
  TFA_SETUP_PAGE_STEP_1_TIP:
    "Μπορείτε να αποθηκεύσετε το παρακάτω κείμενο σε περίπτωση που χάσετε πρόσβαση στο τηλέφωνο ή στην εφαρμογή επαλήθευσης σας.",
  TFA_SETUP_PAGE_STEP_2_HEADER: "2. Εισάγετε τον 6-ψηφίων κωδικό εδώ",
  TFA_SETUP_PAGE_STEP_2_CONTENT:
    "Εισάγετε τον κωδικό στο παρακάτω πεδίο. Με την σύνδεση, θα αποδεχόμαστε τους παραγόμενους κωδικούς κάθε φορά που συνδέεστε.",
  TFA_SETUP_PAGE_BUTTON: "Υποβολή",
};
