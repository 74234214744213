export const enLanguageSettings = {
  SETTINGS_DISPLAY_LANGUAGE_HEADER: "Language",
  SETTINGS_DISPLAY_LANGUAGE_MESSAGE:
    "Select the display language of the application.",
  SETTINGS_DISPLAY_THEME_HEADER: "Theme",
  SETTINGS_DISPLAY_THEME_MESSAGE:
    "Select the display theme of the application.",
};

export const elLanguageSettings = {
  SETTINGS_DISPLAY_LANGUAGE_HEADER: "Γλώσσα",
  SETTINGS_DISPLAY_LANGUAGE_MESSAGE:
    "Επιλέξτε την γλώσσα εμφάνισης της εφαρμογής.",
  SETTINGS_DISPLAY_THEME_HEADER: "Θέμα",
  SETTINGS_DISPLAY_THEME_MESSAGE: "Επιλέξτε το θέμα εμφάνισης της εφαρμογής.",
};
