import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import { AppDispatch } from "../../../store/configureStore";
import { ROUTES } from "../../../constants/routes";
import { logout } from "../../../store/actions/user";
import {
  getSettingsInStorage,
  getUserInStorage,
} from "../../../utilities/helpers";

const UserMenu: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const profileData = getUserInStorage();
  const settings = getSettingsInStorage();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    try {
      if (settings) {
        const { authActive } = settings;
        authActive && dispatch(logout()).unwrap();
        navigate(authActive ? ROUTES.LOGIN : ROUTES.HOME);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSettings = () => {
    handleCloseUserMenu();
    try {
      navigate(ROUTES.SETTINGS);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar
            alt={profileData?.name || "?"}
            src="/static/images/avatar/2.jpg"
          />
        </IconButton>
      </Tooltip>
      <Menu
        disableScrollLock
        sx={{ mt: "45px" }}
        id="menu-appbar"
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          sx={{
            paddingRight: 1,
            borderBottom: "1px solid rgba(220,220,220, 0.2)",
          }}
        >
          <Box
            onClick={handleSettings}
            style={{
              textDecoration: "none",
              color: "inherit",
              minWidth: "100px",
              display: "flex",
            }}
          >
            <Typography sx={{ textAlign: "center", flex: 2 }}>
              {t("USER_MENU_ITEM_SETTINGS")}
            </Typography>
            <SettingsIcon sx={{ flex: 1 }} />
          </Box>
        </MenuItem>
        <MenuItem sx={{ paddingRight: 1 }}>
          <Box
            onClick={handleLogout}
            style={{
              textDecoration: "none",
              color: "inherit",
              minWidth: "100px",
              display: "flex",
            }}
          >
            <Typography sx={{ textAlign: "center", flex: 2 }}>
              {t("USER_MENU_ITEM_LOGOUT")}
            </Typography>
            <LogoutIcon sx={{ flex: 1 }} />
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;
