export const enTfaPage = {
  TFA_PAGE_HEADER: "Two factor authentication",
  TFA_PAGE_AUTH_CODE_LABEL: "Enter your verification code to continue",
};

export const elTfaPage = {
  TFA_PAGE_HEADER: "Ταυτοποίηση δύο παραγόντων",
  TFA_PAGE_AUTH_CODE_LABEL:
    "Εισάγετε τον κωδικό επαλήθευσης για να συνεχίσετε.",
};
