export const SIGNAL_FETCH_BY_OPENED_AT = "signal/fetch/by/openedAt";
export const SIGNAL_FETCH_BY_PAIR = "signal/fetch/by/pair";
export const SIGNALS_CALCULATE = "signals/calculate";
export const ANALYSIS_FETCH_BY_OPENED_AT = "analysis/fetch/by/openedAt";
export const ANALYSIS_FETCH_BY_PAIR = "analysis/fetch/by/pair";
export const CHARTS_FETCH_BY_DATE = "charts/fetch/by/date";
export const PERIODS_FETCH_LAST_SYNCED = "periods/fetch/last/synced";
export const ECONOMY_ANALYSIS_FETCH_BY_EXTERNAL_ID =
  "economy/fetch/by/externalid";
export const ECONOMY_ANALYSIS_FETCH_ECONOMY_GROUP =
  "economy/fetch/economy/group";
export const OTHER_FETCH_RANKING = "other/fetch/ranking";
export const OTHER_FETCH_FEARANDGREED = "other/fetch/fearandgreed";
export const CANDLES_FETCH = "candles/fetch";
export const USER_LOGIN = "user/login";
export const USER_LOGOUT = "user/logout";
export const USER_VERIFY = "user/verify";
export const USER_ENABLE_TFA = "user/tfa/enable";
export const USER_DISABLE_TFA = "user/tfa/disable";
export const USER_REQUEST_TFA = "user/tfa/request";
export const USER_FETCH_SESSION = "user/fetch/session";
export const USER_FETCH_SETTINGS = "user/fetch/settings";
export const USER_UPDATE_SETTINGS = "user/update/settings";
export const APP_FETCH_SETTINGS = "app/fetch/settings";
