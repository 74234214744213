import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
} from "@mui/material";
import ToggleTheme from "../ToggleTheme/ToggleTheme";
import BaseSelect from "../../Common/BaseSelect/BaseSelect";
import { useState } from "react";
import { commonSelector } from "../../../components/selectors";
import { TAppState } from "../../../store/reducers";

type Props = {
  open: boolean;
  onClickOk: (p: any, e: React.SyntheticEvent<unknown>, r?: string) => void;
  onClickCancel: (p: any, e: React.SyntheticEvent<unknown>, r?: string) => void;
};

const SettingsDialog: React.FC<Props> = ({
  open,
  onClickOk,
  onClickCancel,
}) => {
  const { t } = useTranslation();
  const { theme, language } = useSelector((state: TAppState) =>
    commonSelector(state)
  );
  const [settingsLocale, setSettingsLocale] = useState(language);
  const [settingsTheme, setSettingsTheme] = useState(theme);

  const onOk = (e: React.SyntheticEvent<unknown>, r?: string) => {
    onClickOk({ locale: settingsLocale, theme: settingsTheme }, e, r);
  };

  const onCancel = (e: React.SyntheticEvent<unknown>, r?: string) => {
    onClickCancel({ locale: settingsLocale, theme: settingsTheme }, e, r);
  };

  return (
    <Dialog disableEscapeKeyDown open={open}>
      <DialogTitle>{t("SETTINGS_DIALOG_TITLE")}</DialogTitle>
      <DialogContent>
        <Box>
          <Box sx={{ m: 1, minWidth: 250, display: "flex" }}>
            <Box sx={{ flex: 1 }}>
              <InputLabel sx={{ lineHeight: "40px" }}>
                {t("SETTINGS_DIALOG_THEME_LABEL")}
              </InputLabel>
            </Box>
            <Box sx={{ flex: 1 }}>
              <ToggleTheme
                currentTheme={theme}
                changeTheme={(theme) => setSettingsTheme(theme)}
              />
            </Box>
          </Box>
          <Box sx={{ m: 1, minWidth: 250, display: "flex" }}>
            <Box sx={{ flex: 1 }}>
              <InputLabel sx={{ lineHeight: "40px" }}>
                {t("APP_LANGUAGE_LABEL")}
              </InputLabel>
            </Box>
            <Box sx={{ flex: 1 }}>
              <BaseSelect
                data={[
                  { title: t("APP_LANGUAGE_OPTION_EL"), locale: "el" },
                  { title: t("APP_LANGUAGE_OPTION_EN"), locale: "en" },
                ]}
                onChange={(e) => setSettingsLocale(e[0].locale)}
                displayKey="title"
                selectKey="locale"
                defaultValue={language}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t("SETTINGS_DIALOG_BUTTON_CANCEL")}</Button>
        <Button onClick={onOk}>{t("SETTINGS_DIALOG_BUTTON_OK")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsDialog;
