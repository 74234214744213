import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppBar, Box, Button, Container, Toolbar } from "@mui/material";
import { ROUTES } from "../../../constants/routes";
import Logo from "./Logo";
import MainMenu from "../Menu/MainMenu";
import UserMenu from "../Menu/UserMenu";
import { SettingsButton } from "../Buttons/Buttons";
import { TAppState } from "../../../store/reducers";
import SettingsDialog from "../SettingsDialog/SettingsDialog";
import { commonSelector, userSelector } from "../../../components/selectors";
import { AppDispatch } from "../../../store/configureStore";
import { languageChanged, themeChanged } from "../../../store/reducers/common";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { settings, theme } = useSelector((state: TAppState) =>
    commonSelector(state)
  );
  const {
    auth: { loggedIn },
    profile,
  } = useSelector((state: TAppState) => userSelector(state));

  const navigateToLogin = () => navigate(ROUTES.LOGIN);

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Logo isMobile={false} />
          {settings?.authActive ? (
            loggedIn ? (
              <MainMenu isMobile />
            ) : null
          ) : (
            <MainMenu isMobile />
          )}
          <Logo isMobile />
          {settings?.authActive ? (
            loggedIn ? (
              <MainMenu isMobile={false} />
            ) : null
          ) : (
            <MainMenu isMobile={false} />
          )}
          <Box sx={{ ml: "auto", display: "flex" }}>
            {!loggedIn ? (
              <SettingsButton
                style={{ color: "white" }}
                onClick={() => setDialogOpen(true)}
              />
            ) : null}
            <SettingsDialog
              open={dialogOpen}
              onClickCancel={(p, e, r) =>
                r !== "backdropClick" && setDialogOpen(false)
              }
              onClickOk={(p, e, r) => {
                const { locale, theme } = p;
                dispatch(languageChanged(locale));
                dispatch(themeChanged(theme));
                setDialogOpen(false);
                window.location.reload();
              }}
            />
            {loggedIn && settings?.authActive && <UserMenu />}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
