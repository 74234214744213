import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../features/api";
import {
  fetchEconomyByExternalId,
  fetchEconomyGroupData,
} from "../actions/economy";
import { AXIOS_STATUSES } from "../../types/entities";

export interface TEconomyState {
  groups: any;
  rows: any;
  recession: {
    data: Array<any>;
  };
  yields: {
    data: Array<any>;
  };
  inflation: {
    data: Array<any>;
  };
  status: string | null;
}

const initialState: TEconomyState = {
  groups: {},
  rows: {},
  recession: {
    data: [],
  },
  yields: {
    data: [],
  },
  inflation: {
    data: [],
  },
  status: null,
};

const economySlice = createSlice({
  name: "economy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEconomyByExternalId.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchEconomyByExternalId.fulfilled, (state, action) => {
        const { externalId } = action.meta.arg;
        state.rows[externalId] = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchEconomyByExternalId.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchEconomyGroupData.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchEconomyGroupData.fulfilled, (state, action) => {
        const { arg } = action.meta;
        const { group } = arg;
        state[group].data = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchEconomyGroupData.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addMatcher(
        api.endpoints.getEconomyGroups.matchFulfilled,
        (state, { payload }) => {
          state.groups = payload;
        }
      )
      .addMatcher(
        api.endpoints.getEconomyRecession.matchFulfilled,
        (state, { payload }) => {
          state.recession.data = payload;
          state.recession.data = payload;
        }
      );
  },
});

export default economySlice.reducer;
