import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { KeyOutlined } from "@mui/icons-material";
import { Box, Avatar, Typography } from "@mui/material";
import { AppDispatch } from "../../../store/configureStore";
import { login } from "../../../store/actions/user";
import BaseAuthCode from "../../Common/BaseAuthCode/BaseAuthCode";
import { ROUTES } from "../../../constants/routes";

const Tfa: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const email = location?.state?.email;
  const password = location?.state?.password;

  const onAuthCodeComplete = async (authCode) => {
    if (email && password && authCode) {
      try {
        const loginData = await dispatch(
          login({ email, password, authCode })
        ).unwrap();
        loginData && navigate(ROUTES.HOME);
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    (!email || !password) && navigate(-1);
  }, []);

  return (
    <Box
      sx={{
        mt: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "primary.light" }}>
        <KeyOutlined />
      </Avatar>
      <Typography variant="h5">{t("TFA_PAGE_HEADER")}</Typography>
      <Box sx={{ mt: 3, display: "grid" }}>
        <Box sx={{ width: 380 }}>
          <Box sx={{ mb: 1, textAlign: "center" }}>
            {t("TFA_PAGE_AUTH_CODE_LABEL")}
          </Box>
          <BaseAuthCode onComplete={onAuthCodeComplete} />
        </Box>
      </Box>
    </Box>
  );
};

export default Tfa;
