import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { first } from "lodash";
import { fetchLastSynced } from "../actions/period";
import { fetchSignalByOpenedAt } from "../actions/signal";
import { filterSignals } from "../../utilities/filters";
import { AXIOS_STATUSES } from "../../types/entities";

export interface TSignalState {
  list: {
    data: Array<any>;
    filteredSignals: Array<any>;
    filters: {
      signal: any | null;
      period: any | null;
      openedAt: any | null;
    };
  };
  status: string | null;
}

const initialState: TSignalState = {
  list: {
    data: [],
    filteredSignals: [],
    filters: {
      signal: null,
      period: null,
      openedAt: null,
    },
  },
  status: null,
};

const signalSlice = createSlice({
  name: "signal",
  initialState,
  reducers: {
    listPeriodChanged: (state, action) => {
      const { period, signals } = action.payload;
      const filteredSignals = filterSignals(signals, period);
      state.list.filters.period = period;
      state.list.filteredSignals = filteredSignals;
      state.list.filters.signal = first(filteredSignals);
    },
    listSignalChanged: (state, action) => {
      state.list.filters.signal = action.payload.signal;
    },
    listOpenedAtChanged: (state, action) => {
      state.list.filters.openedAt = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLastSynced.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchLastSynced.fulfilled, (state, action) => {
        state.list.filters.openedAt = DateTime.fromFormat(
          action.payload.openedAt,
          "yyyy-MM-dd HH:mm:ss",
          { zone: "utc" }
        );
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchLastSynced.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchSignalByOpenedAt.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchSignalByOpenedAt.fulfilled, (state, action) => {
        state.list.data = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchSignalByOpenedAt.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      });
  },
});

export const { listPeriodChanged, listSignalChanged, listOpenedAtChanged } =
  signalSlice.actions;
export default signalSlice.reducer;
