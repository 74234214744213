export const enThemeSettings = {
  SETTINGS_DISPLAY_THEME_HEADER: "Theme",
  SETTINGS_DISPLAY_THEME_MESSAGE:
    "Select the display theme of the application.",
};

export const elThemeSettings = {
  SETTINGS_DISPLAY_THEME_HEADER: "Θέμα",
  SETTINGS_DISPLAY_THEME_MESSAGE: "Επιλέξτε το θέμα εμφάνισης της εφαρμογής.",
};
