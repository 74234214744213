import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { TAppState } from "../../../../../store/reducers";
import { AppDispatch } from "../../../../../store/configureStore";
import { updateSettings } from "../../../../../store/actions/user";
import { getUserInStorage } from "../../../../../utilities/helpers";
import { settingsSelector } from "../../../../../components/selectors";

const ReportSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const user = getUserInStorage();

  const {
    notifications: { sendMail = null },
  } = useSelector((state: TAppState) => settingsSelector(state));

  const toggleEmail = async (enable = false) => {
    try {
      await dispatch(updateSettings({ sendMail: enable ? 1 : 0 })).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t("SETTINGS_NOTIFICATIONS_REPORT_HEADER")}
      </Typography>
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t("SETTINGS_NOTIFICATIONS_REPORT_EMAIL_HEADER")}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flex: 3, mr: 2 }}>
            {t("SETTINGS_NOTIFICATIONS_REPORT_EMAIL_MESSAGE")}
          </Box>
          <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
            {sendMail ? (
              <Box sx={{ mr: 3, display: "flex" }}>
                <ToggleOnIcon sx={{ mr: 1, borderRadius: "50%" }} />
                <Box>{t("SETTINGS_NOTIFICATIONS_REPORT_EMAIL_STATUS_ON")}</Box>
              </Box>
            ) : (
              <Box sx={{ mr: 3, display: "flex" }}>
                <ToggleOffIcon sx={{ mr: 1, borderRadius: "50%" }} />
                <Box>{t("SETTINGS_NOTIFICATIONS_REPORT_EMAIL_STATUS_OFF")}</Box>
              </Box>
            )}
            {sendMail ? (
              <Button variant="contained" onClick={() => toggleEmail()}>
                {t("SETTINGS_NOTIFICATIONS_REPORT_EMAIL_DISABLE_BUTTON")}
              </Button>
            ) : (
              <Button variant="contained" onClick={() => toggleEmail(true)}>
                {t("SETTINGS_NOTIFICATIONS_REPORT_EMAIL_ENABLE_BUTTON")}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReportSettings;
