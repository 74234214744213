import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  SIGNAL_FETCH_BY_OPENED_AT,
  SIGNAL_FETCH_BY_PAIR,
  SIGNALS_CALCULATE,
} from "./_types";
import {
  setInterceptors,
  clearInterceptors,
} from "../../features/interceptors/app";
import { signalsUrl } from "./../../constants/endpoints";

type TFetchSignalPayload = {
  signalId?: number | null;
  periodId: number | null;
  openedAt?: any | null;
  closedAt?: any | null;
  pairId?: number | null;
};

type TCalculateSignalsPayload = {
  periodId: string;
  data: string | "";
};

export const fetchSignalByOpenedAt = createAsyncThunk(
  SIGNAL_FETCH_BY_OPENED_AT,
  async (params: TFetchSignalPayload) => {
    const { signalId, periodId, openedAt } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.get(
      `${signalsUrl}?signalId=${signalId}&periodId=${periodId}&openedAt=${openedAt.toSeconds()}`
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const fetchSignalBySignal = createAsyncThunk(
  SIGNAL_FETCH_BY_PAIR,
  async (params: TFetchSignalPayload) => {
    const { periodId, signalId } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.get(
      `${signalsUrl}?periodId=${periodId}&signalId=${signalId}`
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const fetchSignalsByPair = createAsyncThunk(
  SIGNAL_FETCH_BY_PAIR,
  async (params: TFetchSignalPayload) => {
    const { periodId, pairId } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.get(
      `${signalsUrl}?periodId=${periodId}&pairId=${pairId}`
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);

export const calculateSignals = createAsyncThunk(
  SIGNALS_CALCULATE,
  async (params: TCalculateSignalsPayload) => {
    const { periodId, data } = params;
    const intercepted = setInterceptors(
      axios.create({ withCredentials: true })
    );
    const response = await intercepted.instance.post(
      `${signalsUrl}/calculate`,
      { periodId, data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    clearInterceptors(intercepted);
    return response.data;
  }
);
