import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LockOutlined } from "@mui/icons-material";
import { Box, Avatar, Typography, Button, Grid } from "@mui/material";
import { AppDispatch } from "../../../store/configureStore";
import { ROUTES } from "../../../constants/routes";
import {
  fetchSession,
  login,
  requestTwoFactor,
} from "../../../store/actions/user";
import BaseEmail from "../../Common/BaseFields/BaseEmail/BaseEmail";
import BasePassword from "../../Common/BaseFields/BasePassword/BasePassword";
import { getSettingsInStorage } from "../../../utilities/helpers";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    if (email && password) {
      try {
        const { forceTwoFactor = null } = getSettingsInStorage();
        const { twoFactor } = await dispatch(
          login({ email, password })
        ).unwrap();
        if (twoFactor) {
          navigate(ROUTES.TFA, { state: { email, password } });
        } else if (forceTwoFactor && !twoFactor) {
          const { secret, uri } = await dispatch(requestTwoFactor()).unwrap();
          navigate(ROUTES.TFA_SETUP, {
            state: { email, password, twoFactor: { secret, uri } },
          });
        } else {
          await dispatch(fetchSession()).unwrap();
          navigate(ROUTES.HOME);
        }
      } catch (e) {
        setEmail("");
        setPassword("");
      }
    }
  };

  return (
    <Box
      sx={{
        mt: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "primary.light" }}>
        <LockOutlined />
      </Avatar>
      <Typography variant="h5">{t("LOGIN_PAGE_HEADER")}</Typography>
      <Box sx={{ mt: 1, display: "grid" }}>
        <BaseEmail
          email={email}
          onChange={(email: string) => setEmail(email)}
          onKeyDown={(key: string) => key === "Enter" && handleLogin()}
        />
        <BasePassword
          password={password}
          onChange={(password: string) => setPassword(password)}
          onKeyDown={(key: string) => key === "Enter" && handleLogin()}
        />
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleLogin}
        >
          {t("LOGIN_PAGE_BUTTON")}
        </Button>
        <Grid container justifyContent={"flex-end"} style={{ display: "none" }}>
          <Grid item>
            <Link to="/register">Don't have an account? Register</Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Login;
