import {
  enReportSettings,
  elReportSettings,
} from "./ReportSettings/translations";

export const enNotificationsTab = {
  SETTINGS_NOTIFICATIONS_TAB_LABEL: "Notifications",
  ...enReportSettings,
};

export const elNotificationsTab = {
  SETTINGS_NOTIFICATIONS_TAB_LABEL: "Ειδοποιήσεις",
  ...elReportSettings,
};
