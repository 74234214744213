import { createSlice } from "@reduxjs/toolkit";
import { first } from "lodash";
import { DateTime } from "luxon";
import { fetchLastSynced } from "../actions/period";
import {
  fetchAnalysisByOpenedAt,
  fetchAnalysisByPair,
} from "../actions/analysis";
import { AXIOS_STATUSES } from "./../../types/entities";
import { getPreactivated } from "../../components/Pages/Analysis/Chart/AnalysisChartConfig";
import {
  filterGraphicalAnalyses,
  filterTabularAnalyses,
} from "./../../utilities/filters";

export interface TAnalysisState {
  list: {
    data: Array<any>;
    filteredAnalyses: Array<any>;
    filters: {
      analysis: any | null;
      period: any | null;
      openedAt: any | null;
    };
  };
  chart: {
    symbol: string;
    name?: string;
    data: Array<any>;
    filteredAnalyses: Array<any>;
    filters: {
      analysis: any | null;
      period: any | null;
      pair: any | null;
    };
    activeMetrics: Array<any>;
  };
  status: string | null;
}

const initialState: TAnalysisState = {
  list: {
    data: [],
    filteredAnalyses: [],
    filters: {
      analysis: null,
      period: null,
      openedAt: null,
    },
  },
  chart: {
    symbol: "",
    name: "",
    data: [],
    filteredAnalyses: [],
    filters: {
      analysis: null,
      period: null,
      pair: null,
    },
    activeMetrics: [],
  },
  status: null,
};

const analysisSlice = createSlice({
  name: "analysis",
  initialState,
  reducers: {
    listPeriodChanged: (state, action) => {
      const { period, analyses } = action.payload;
      const filteredAnalyses = filterTabularAnalyses(analyses, period);
      state.list.filters.period = period;
      state.list.filteredAnalyses = filteredAnalyses;
      state.list.filters.analysis = first(filteredAnalyses);
    },
    listAnalysisChanged: (state, action) => {
      state.list.filters.analysis = action.payload.analysis;
    },
    listOpenedAtChanged: (state, action) => {
      state.list.filters.openedAt = action.payload;
    },
    chartPeriodChanged: (state, action) => {
      const { period, analyses } = action.payload;
      const filteredAnalyses = filterGraphicalAnalyses(analyses, period);
      const analysis = first(filteredAnalyses);
      state.chart.filters.period = action.payload.period;
      state.chart.filteredAnalyses = filteredAnalyses;
      state.chart.filters.analysis = analysis;
      state.chart.activeMetrics = getPreactivated(analysis?.CODE);
    },
    chartPairChanged: (state, action) => {
      state.chart.filters.pair = action.payload.pair;
    },
    chartAnalysisChanged: (state, action) => {
      state.chart.filters.analysis = action.payload.analysis;
      state.chart.activeMetrics = getPreactivated(
        action.payload.analysis?.CODE
      );
    },
    chartMetricChanged: (state, action) => {
      const { value, checked } = action.payload;
      const active = state.chart.activeMetrics;
      state.chart.activeMetrics = !checked
        ? active.filter((m) => m !== value)
        : [...active, value];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLastSynced.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchLastSynced.fulfilled, (state, action) => {
        state.list.filters.openedAt = DateTime.fromFormat(
          action.payload.openedAt,
          "yyyy-MM-dd HH:mm:ss",
          { zone: "utc" }
        );
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchLastSynced.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchAnalysisByOpenedAt.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchAnalysisByOpenedAt.fulfilled, (state, action) => {
        state.list.data = action.payload;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchAnalysisByOpenedAt.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      })
      .addCase(fetchAnalysisByPair.pending, (state, action) => {
        state.status = AXIOS_STATUSES.LOADING;
      })
      .addCase(fetchAnalysisByPair.fulfilled, (state, action) => {
        const row = first(action.payload);
        state.chart.data = action.payload;
        state.chart.symbol = row?.SYMBOL.replaceAll(row.SYMBOL_PREFIX, "");
        state.chart.name = first(action.payload)?.NAME;
        state.status = AXIOS_STATUSES.IDLE;
      })
      .addCase(fetchAnalysisByPair.rejected, (state, action) => {
        state.status = AXIOS_STATUSES.ERROR;
      });
  },
});

export const {
  listPeriodChanged,
  listOpenedAtChanged,
  listAnalysisChanged,
  chartPeriodChanged,
  chartPairChanged,
  chartAnalysisChanged,
  chartMetricChanged,
} = analysisSlice.actions;

export default analysisSlice.reducer;
