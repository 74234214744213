import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { PromiseWithKnownReason } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { QueryFulfilledRejectionReason } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import i18n from "i18next";
import { API } from "./../constants/settings";
import { ROUTES } from "./../constants/routes";
import { THttpResponseBody } from "../types/entities";
import { history } from "../utilities/history";
import { httpErrorChanged } from "../store/reducers/common";
import { changeLoggedIn } from "../store/reducers/user";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "@reduxjs/toolkit";
import {
  getSettingsInStorage,
  removeUserInStorage,
} from "../utilities/helpers";

const handleErrors = async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  queryFulfilled: PromiseWithKnownReason<
    {
      data: any;
      meta: FetchBaseQueryMeta | undefined;
    },
    QueryFulfilledRejectionReason<
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >
    >
  >
) => {
  try {
    await queryFulfilled;
  } catch (e: any) {
    const status = e?.error?.status;
    const errorMessage = e?.error?.extra?.message;
    dispatch(
      httpErrorChanged({
        errorStatus: true,
        errorMessage:
          errorMessage || i18n.t(`NOTIFICATIONS_HTTP_ERROR_${status}`),
      })
    );
    if (status === 401) {
      const settings = getSettingsInStorage();
      const { authActive = null } = settings;
      removeUserInStorage();
      dispatch(changeLoggedIn(false));
      history.replace(authActive ? ROUTES.LOGIN : ROUTES.HOME);
    }
  }
};

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API.URL,
    timeout: API.TIMEOUT,
    credentials: "include",
    validateStatus: (response: Response, body: THttpResponseBody) =>
      response.status === 200 && body.success,
  }),
  endpoints: (build) => ({
    getActivePeriods: build.query<any, void>({
      query: () => "/periods/active",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getSourcePeriods: build.query<any, void>({
      query: () => "/periods/source",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getActivePairs: build.query<any, void>({
      query: () => "/pairs/active",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getLivePairs: build.query<any, void>({
      query: () => "/pairs/live",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getLiveProviders: build.query<any, void>({
      query: () => "/providers/live",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getActiveIndicators: build.query<any, void>({
      query: () => "/indicators/active",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getActivePatterns: build.query<any, void>({
      query: () => "/patterns/active",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getActiveSignals: build.query<any, void>({
      query: () => "/signals/active",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getActiveAnalyses: build.query<any, void>({
      query: () => "/analyses/active",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getEconomyGroups: build.query<any, void>({
      query: () => "/economy/groups/active",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
    getEconomyRecession: build.query<any, void>({
      query: () => "/economy/recession",
      transformResponse: (body: THttpResponseBody) => body.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        handleErrors(dispatch, queryFulfilled);
      },
    }),
  }),
});

export const {
  useGetActivePeriodsQuery,
  useGetSourcePeriodsQuery,
  useGetActivePairsQuery,
  useGetLivePairsQuery,
  useGetLiveProvidersQuery,
  useGetActiveIndicatorsQuery,
  useGetActivePatternsQuery,
  useGetActiveSignalsQuery,
  useGetActiveAnalysesQuery,
  useGetEconomyGroupsQuery,
  useGetEconomyRecessionQuery,
} = api;
