export const filterSignals = (signals: any, period: any) => {
  return signals?.filter((signal: any) => {
    const { config } = signal;
    const { parameters } = config;
    return period?.CODE && parameters[period.CODE];
  });
};

export const filterTabularAnalyses = (analyses: any, period: any) => {
  return analyses?.filter((analysis: any) => {
    const { config } = analysis;
    const { properties, parameters } = config;
    return period?.CODE && parameters[period.CODE] && properties.isTabular;
  });
};

export const filterGraphicalAnalyses = (analyses: any, period: any) => {
  return analyses?.filter((analysis: any) => {
    const { config } = analysis;
    const { properties, parameters } = config;
    return period?.CODE && parameters[period.CODE] && properties.isGraphical;
  });
};
