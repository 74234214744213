import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import LeftRightChart from "../../../../Common/LeftRightChart/LeftRightChart";
import { AppDispatch } from "../../../../../store/configureStore";
import { EChartDrawTypes } from "../../../../../constants/enums";
import { economySelector } from "../../../../selectors";
import { TAppState } from "../../../../../store/reducers";
import { fetchEconomyByExternalId } from "../../../../../store/actions/economy";
import {
  createChartEconomy,
  createHorizontalLine,
} from "../../../../../utilities/helpers";
import {
  getHorizontalLineConfig,
  getLegendLabel,
  getLineConfig,
} from "../ChartLines";
import { useGetEconomyGroupsQuery } from "../../../../../features/api";

const EconomyConsumerTab: React.FC = () => {
  const { t } = useTranslation();
  const { data: groups, isLoading } = useGetEconomyGroupsQuery();
  const dispatch = useDispatch<AppDispatch>();
  const { rows } = useSelector((state: TAppState) => economySelector(state));
  const series = useMemo(
    () => groups?.consumer?.series || [],
    [groups?.consumer?.series]
  );

  useEffect(() => {
    if (!isLoading) {
      for (const serie of series) {
        const externalId = serie.serieExternalId;
        dispatch(fetchEconomyByExternalId({ externalId }));
      }
    }
  }, [dispatch, series, isLoading]);

  return (
    <Box sx={{ width: "100%" }}>
      <div style={{ display: "flex", flexFlow: "wrap", gap: "5%" }}>
        {series.map((serie: any, key: any) => {
          const { serieExternalId, referenceValue } = serie;
          const rightData = rows?.[serieExternalId];
          return (
            <div
              key={key}
              style={{
                minWidth: window.innerWidth >= 900 ? "45%" : "90%",
                flex: 1,
                paddingBottom: "5%",
              }}
            >
              {isLoading || !rightData ? (
                <></>
              ) : (
                <div>
                  <LeftRightChart
                    fullScreen={false}
                    autoSize={true}
                    height={300}
                    rightDrawType={EChartDrawTypes.Area}
                    rightConfig={getLineConfig(serieExternalId)}
                    rightData={createChartEconomy(rightData)}
                    rightLegendLabel={getLegendLabel(serie)}
                    horizontalLineConfig={getHorizontalLineConfig(serie)}
                    horizontalLineData={createHorizontalLine(
                      rightData,
                      referenceValue
                    )}
                  />
                  <div style={{ fontSize: "80%", paddingTop: "2%" }}>
                    {t(`ECONOMY_INDEX_DESCRIPTION_${serieExternalId}`)}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Box>
  );
};

export default EconomyConsumerTab;
